import React, { useEffect, useRef, useState } from 'react'
import Reveal from '../reveal'
import Img from 'gatsby-image'

const ScreensCarousel = ({ items, blueBackground }) => {
  const activeItemRef = useRef(null)
  const phoneRef = useRef(null)
  const lastTouchY = useRef(0)

  useEffect(() => {
    if (phoneRef.current) {

      phoneRef.current.addEventListener('wheel', (e) => {
        const { current } = activeItemRef
        if (current) {
          if (e.deltaY < 0 && current.scrollTop === 0) {

            return true
          }
          if (e.deltaY > 0 && current.scrollHeight - current.scrollTop - current.clientHeight === 0) return true
          e.preventDefault()
          current.scrollBy(0, e.deltaY)
        }
      }, {passive: true})

      phoneRef.current.addEventListener('touchstart', (e) => {
        lastTouchY.current = e.touches[0].clientY

        return true
      }, {passive: true})

      phoneRef.current.addEventListener('touchmove', (e) => {
        return true
        /* const deltaY = e.touches[0].clientY - lastTouchY.current
        lastTouchY.current = e.touches[0].clientY
        const { current } = activeItemRef

        if (deltaY > 0 && current.scrollTop === 0) return true
        if (deltaY < 0 && current.scrollHeight - current.scrollTop - current.clientHeight === 0) return true

        if (current) {
          current.scrollBy(0, deltaY * -1)
        } */
      }, {passive: true})
    }
  }, [])

  const [index, setIndex] = useState(0)

  const next = () => {
    setIndex((i) => {
      if (i + 1 > items.length - 1) {
        return 0;
      } else {
        return i + 1;
      }
    })
  }

  const prev = () => {
    setIndex((i) => {
      if (i - 1 < 0) {
        return items.length - 1;
      } else {
        return i - 1;
      }
    })
  }

  return (
    <section className={`wrapper screens-carousel ${blueBackground ? 'blue' : ''}`}>
      <Reveal>
        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-md-6 push-md-3'}>
              <div>
                <h2 className={'cs-title'}>Užívateľské rozhranie (UI) appky</h2>
                <p className="description">Ušité presne na mieru.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="slider">
          <div className={'buttons'}>
            <button onClick={() => prev()}>
              <i className="la la-angle-left"></i>
              <svg viewBox="0 0 44 44" className="circle-animation">
                <circle className="back" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
                <circle className="outer" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
              </svg>
            </button>

            <button onClick={() => next()}>
              <i className="la la-angle-right"></i>
              <svg viewBox="0 0 44 44" className="circle-animation">
                <circle className="back" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
                <circle className="outer" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
              </svg>
            </button>
          </div>
          <div className="items" style={{ transform: `translateX(calc(${index * -1} * var(--item-offset)))` }}>
            {items.map((item, i) => {
              return (
                <div className={`item ${i === index ? 'active' : ''}`} ref={i === index ? activeItemRef : undefined} key={i}>
                  <Img fluid={item.childImageSharp.fluid} alt={'screen'} className={'item-img'}/>
                </div>
              )
            })}
          </div>
        </div>
        <div className="dots">
          {[...Array(items.length)].map((_, i) => (<div className={`dot ${index === i ? 'active' : ''}`} key={i}/>))}
        </div>
      </Reveal>
    </section>
  )
}

export default ScreensCarousel
