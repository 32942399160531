import React, { Component } from 'react'
import Landing from '../../../../components/case-studies/Landing'
import AboutProject from '../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../components/case-studies/PagesShowcase'
import ProjectShortDescription from '../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../components/case-studies/ColorShowcase'
import Footer from '../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../components/reveal'
import ScreensCarousel from '../../../../components/case-studies/ScreensCarousel'
import { removeNumberPrefix } from '../../../../utilities'
import ProjectImageLightBox from '../../../../components/case-studies/ProjectImageLightBox'

class Letenky extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Vývoj mobilnej aplikácie CliqDate'}
          description={'Prípadová štúdia'}
          description2={'Od názvu, cez logo, UX dizajn, až po programovanie aplikácie a portálu pre prémiovú zoznamovaciu platformu.'}
          text={'Pre projekt CliqDate sme vymysleli názov brandu, logo, prepracovaný design vrátane mobilnej aplikácie a to všetko sme naprogramovali na mieru. Zoznámiť sa v online svete nebolo nikdy jednoduchšie'}
          img={data.landingImage}
          pageMode={true}
          noShadow={true}
          url="https://narative.sk/nase-prace/pripadove-studie/aplikacie/cliqdate"
          what_we_done={[
            { text: 'Tvorba názvu' },
            { text: 'logo & branding' },
            { text: 'ux & web design' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (php)', link: '/nase-prace/webstranky/portaly/cliqdate' },
            { text: 'mobilná aplikácia', link: '/nase-prace/aplikacie/cliqdate-app' },
          ]} />

        <AboutProject
          title="Vytvorenie aplikácie na mieru musí mať hlavu a pätu"
          text={'Intuitívna a funkčná appka si vyžaduje profesionálny prístup.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: 'Od klienta sme dostali zadanie na tvorbu zoznamovacej appky a portálu s moderným <a class="blue-link" href="/ux-ui-dizajn">dizajnom</a>, ktoré pomôžu mladým ľuďom spoznať nových priateľov alebo partnera. Najskôr sme si s klientom prešli všetky požiadavky, aby sme <b>pochopili jeho očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Cieľom bola prémiová <a class="blue-link" href="/tvorba-mobilnych-aplikacii">aplikácia na mieru</a>, v ktorej sa človek jednoducho zoznámi s novými ľuďmi a zároveň sa appka odlíši od fádnej konkurencie. Musí mať maximálne intuitívny dizajn, a zároveň najlepšiu technologickú úroveň <b>funkcií, rýchlosti a SEO</b>. V rámci úzkej spolupráce sme s klientom diskutovali, ako tieto plány zrealizovať.',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Ďalej sa vymyslel názov, <a class="blue-link" href="/tvorba-loga">logo</a> a celá <a class="blue-link" href="/branding">korporátna identita</a>. Po schválení sme spraocvali wireframy (rozloženia stránok) a finálny dizajn aplikácie. Pravidelne sme klientovi prezentovali výstupy <b>a diskutovali o nich</b>. Projekt sme finálne zverili do rúk našich programátorov.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením appky optimalizujeme, vylaďujeme detailne responzívnosť a všetko finálne testujeme. Odovzdaním spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Samozrejmosťou pre programovanie Android, iOS či multiplatformových appiek je <b>spoľahlivý servis a optimalizácia</b>, aby appky išli s dobou. ',
            }
          ]}
        />

        <ProjectShortDescription
          title={'Tvorba brandu'}
          description={'Predpokladom plnohodnotnej firemnej identity je pochopenie projektu a jeho zámeru. Návrh loga prichádza potom.'}
          text={'Po konzultáciách s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal projekt prezentovať. Následne sme odkomunikovali základné piliere toho, ako sa <b>odlíšiť od konkurencie</b> a cez nový branding značky ukázať jedinečnú esenciu firmy.'} />

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#d61e3c', tintColorCode: '#de4b63', nameColor: '#FFFFFF', name: 'Rumelková červená' },
          { code: '#f86c61', tintColorCode: '#f98880', nameColor: '#FFFFFF', name: 'Lososová' },
          { code: '#44222f', tintColorCode: '#694e59', nameColor: '#FFFFFF', name: 'Kávová' },
        ]} />

        <PagesShowcase
          fixedTitleWidth={true}
          title={'Dizajn aplikácie na mieru'}
          description={'Vytvorenie mobilnej aplikácie sa bez neho nezaobíde.'}
          text={'Svieži dizajn pre CliqDate obsahuje všetko, čo by mala moderná appka spĺňať. Nielen jedinečnosť a estetickosť, ale aj responzivita, intuitívne používateľské rozhranie, prehľadnosť a funkčnosť sú atribúty, ktoré používateľ očakáva od appky, ktorú bude pravidelne využívať.'}
          projectDetail={'/nase-prace/webstranky/portaly/cliqdate'}
          pages={data.pages.nodes.map(node => ({ image: node, name: removeNumberPrefix(node.name) }))} />

        <ScreensCarousel
          items={data.screens.nodes}
        />

        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="letenky" />
          </div>
        </Reveal>

        <div className={'dark-section'}>
          <Reveal>
            <div className={'container wrapper'}>
              <div className={'row'}>
                <div className={'col-md-7'}>
                  <h2 className={'cs-title'}>Dizajn mobilnej aplikácie</h2>
                  <p className="description">Dokonalé UI a UX v každom jednom detaile.</p>
                  <p className="description-smaller">Vytvoriť responzívnu appku do posledného detailu je kľúčové, pretože určuje, či bude <a href='/ux-ui-dizajn' class='red-link'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na všetkých typoch smartfónov, tabletov a ďalších rozlíšeniach. <br /><br />Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastnú appku a chce, aby mu prinášala čo najväčší zisk.</p>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-md-4 img-col-1'}>
                  <Img alt={this.constructor.name} fluid={data.mobileGallery.nodes[0].childImageSharp.fluid} />
                  <Img className={'mt-5'} alt={this.constructor.name} fluid={data.mobileGallery.nodes[3].childImageSharp.fluid} />
                </div>
                <div className={'col-md-4 img-col-2'}>
                  <Img alt={this.constructor.name} fluid={data.mobileGallery.nodes[1].childImageSharp.fluid} />
                  <Img className={'mt-5'} alt={this.constructor.name} fluid={data.mobileGallery.nodes[4].childImageSharp.fluid} />
                </div>
                <div className={'col-md-4 img-col-3'}>
                  <Img alt={this.constructor.name} fluid={data.mobileGallery.nodes[2].childImageSharp.fluid} />
                  <Img className={'mt-5'} alt={this.constructor.name} fluid={data.mobileGallery.nodes[5].childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </Reveal>
        </div>

        {/*<ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.cliqdate.sk" target='_blank' className='link-secondary'>www.cliqdate.sk</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Flipwork'}
          nextProjectUrl={'/nase-prace/pripadove-studie/aplikacie/flipwork/'}

        />
      </Layout>
    )
  }
}


export const query = graphql`{
    project: markdownRemark(fields: {slug: {regex: "/cliqdate/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/cliqdate/visual.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/cliqdate/logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    mobileGallery: allFile(filter: { relativePath: {regex: "/(case-studies/cliqdate/mobileGallery/)/"} }) {
        nodes {
            childImageSharp {
                fluid( maxWidth: 700) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    pages: allFile(filter: { relativePath: {regex: "/(case-studies/cliqdate/pages/)/"} }, sort: {fields:name}) {
        nodes {
            name
            childImageSharp {
                fluid( maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    screens: allFile(filter: { relativePath: {regex: "/(case-studies/cliqdate/screens/)/"} }, sort: {fields:name}) {
        nodes {
            name
            childImageSharp {
                fluid( maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
}
`

export default Letenky






